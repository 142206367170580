.content1 {
  transform: translateX(0);
  transition: transform 0.5s ease;
  width: 100%;
  will-change: margin-left;
}

.content1.collapsed {
  transform: translateX(80px);
}

.layoutUserLogin {
  display: flex;
  align-items: center;
}