.container-checksheet {
  margin: 0 10px;
  border: 3px solid black;

  .checksheet-form-item.ant-form-item .ant-form-item-control-input-content {
    display: flex;
  }
  .checksheet-form-item.item-text-area .ant-row.ant-form-item-row {
    height: 100% !important;
  }

  .checksheet-form-item.item-text-area .ant-form-item-control-input {
    height: 100% !important;
  }

  .checksheet-form-item.item-text-area .ant-form-item-control-input-content {
    height: 100%;
  }

  .checksheet-form-item-61 .ant-row.ant-form-item-row {
    height: 100% !important;

    .ant-form-item-control-input {
      height: 100% !important;

      .ant-form-item-control-input-content {
        height: 100% !important;
      }
    }
  }

  .select .ant-form-item-control-input-content {
    height: 100%;
  }

  .checksheet-form-item.ant-form-item.ant-form-item {
    margin-bottom: 0 !important;
  }

  .checksheet-form-item.ant-form-item.ant-form-item
    .ant-form-item-control-input {
    min-height: 0 !important;
  }

  .checksheet-form-item.item-input .ant-row.ant-form-item-row {
    height: 100% !important;
  }

  .checksheet-form-item.item-input
    .ant-row.ant-form-item-row
    .ant-form-item-control-input {
    height: 100% !important;
  }

  .page-check-sheet-input.ant-input-outlined {
    border-color: transparent !important;
    // background: transparent !important;
    box-shadow: none !important;
    font-size: 12px;
    &[data-input-body-center="true"] {
      text-align: center;
    }
  }

  .page-check-sheet-input.custom-input-antd.ant-input {
    padding: 4px 2px !important;
  }

  .ant-select-selector {
    border: none !important;
  }

  .body-1 {
    .body-1-area-left {
      .body-1-area-left-col {
        border-right: 2px solid black;
        border-bottom: 2px solid black;
        display: flex;

        &[data-display="false"] {
          justify-content: center;
          align-items: center;
        }
      }
    }

    .body-1-area-center-col {
      border-bottom: 2px solid black;
    }

    .body-1-area-right-col {
      border-left: 2px solid black;
      border-bottom: 2px solid black;

      .right-custom {
        border-bottom: 2px solid black;
      }
    }

    .body-1-area-item-name {
      width: 75px;
      text-align: center;
      background: #ced4da;
      font-weight: 500;

      &[data-no-background="true"] {
        background: transparent;
      }
    }
  }

  .body-2 {
    .body-2-area-left {
      border-right: 2px solid black;
    }
  }

  .body-3 {
    .body-3-area-left {
      border-right: 2px solid black;
    }
  }
}

.ant-radio-button-wrapper {
  margin: 0 5px !important;
}

.ant-select-dropdown {
  width: 80px !important;
}

.ant-select-dropdown .ant-select-item-option-content {
  text-overflow: unset !important;
  font-size: 12px;
  color: black;
}

.select-check-sheet.ant-select .ant-select-selection-item {
  text-overflow: unset !important;
}

.select-check-sheet .ant-select-selector {
  padding: 0 7px !important;
}

label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.check-box-checksheet
  .ant-checkbox
  .ant-checkbox-inner {
  border: 1px solid red !important;
}

.custom-radio-button .ant-radio-button-wrapper{
  padding-inline: 3px !important;
}

.style-error .ant-select-selector, .style-error-input,
.style-error-radio-btn, .style-error-radio-btn label {
  background-color: #fad4e7 !important;
}

.style-error-checkbox {
  background-color: #fad4e7 !important;
  width: 25px;
  text-align: center;
}

.align-col-checkbox {
  display: flex;
  justify-content: flex-end;
  padding-right: 2px;
}